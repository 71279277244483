.photos-promo {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding-top: 4.375rem;
  padding-bottom: 3.75rem;
  margin-bottom: 10.625rem;
  margin-top: 9.375rem;
  @include md-max {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  @include sm-max {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
    padding-bottom: 1.875rem;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
  }
}

.photos-promo__content {
  width: 61.125rem; //67.916666667%; //978
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.25rem;
  position: relative;
  @include sm-max {
    margin-top: 3.4375rem;
    margin-bottom: 0;
    position: static;
  }
}

.photos-promo__image {
  position: absolute;
  top: -5.875rem;
  right: 0;
  z-index: 2;
  @media (max-width: 62.5em) {
    display: none;
  }
}

.photos-promo__text {
  padding-left: 0; //160
  padding-left: 9rem;
  padding-right: 21rem; //336
  @media (max-width: 62.5em) {
    padding-right: 0;
  }
  @include sm-max {
    padding-left: 0;
  }
}
.photos-promo__app-icon {
  position: absolute;
  top: 0;
  left: 0;
  @include sm-max {
    margin-left: -60px;
    top: 2.75rem;
    left: 50%;
    z-index: 2;
  }
}

.photos-promo__app-icon > svg {
  margin: 0;
}
