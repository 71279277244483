.hero-partner-info {
  margin-bottom: 2rem;
}

.partner-info-index {
  width: 100%;
  @include centeredContentLg;
  padding-bottom: $space4;

  &:nth-child(2n + 2) {
    background-color: #fbfbfb;
  }

  &__header {
    padding-bottom: $space4;
  }

  &__heading {
    font-size: 2rem;

    @include from-medium {
      font-size: 2.7rem;
    }
  }

  &-columns {
    @include centeredContentLg;
    width: 100%;
    display: flex;

    font-size: 1.125rem;
    line-height: 2rem;
    text-align: left;
    @include to-medium {
      flex-flow: column nowrap;
      align-items: center;
    }
    @include from-medium {
      flex-flow: row wrap;
      justify-content: center;
    }
    p {
      margin-top: 0;
      margin-bottom: 2.5rem;
    }
    &__heading {
      font-size: 1.3125rem;
      font-weight: normal;
      margin: 0;
      margin-bottom: $space1;
    }

    &__item {
      @include to-medium {
        width: 100%;
        max-width: 378px;
        margin-bottom: $space3;
      }
      @include from-medium {
        padding-left: $space2;
        padding-right: $space2;
        margin-bottom: $space2;
        width: 50%;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      ul li {
        display: flex;
      }

      ul li:before {
        content: '✓';
        color: #59c150;
        margin-right: 4px;
      }
    }

    section:first-of-type {
      p {
        margin-bottom: 1rem;
      }
    }

    @include from-medium {
      section:first-of-type {
        padding-right: 2.5rem;
      }
    }
  }

  &-footer {
    p {
      margin-top: 4rem;
      margin-bottom: 2rem;
    }
  }
}
