html {
  height: 100%;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  font-weight: $font-light;
  font-size: 100%;
  line-height: 1.291666667;
  -webkit-font-smoothing: antialiased;
  background-color: #f1f0f3;
  color: #45434c;
  min-height: 100%;
  width: 100%;
  height: 100%;
  text-align: center;
  .wf-loaded & {
    font-family: $font-family;
  }
}

.wrap {
  display: flex;
  flex-flow: column nowrap;
  background: #fff;
  width: 100%;
  max-width: $xlMin; // 1440
  margin: 0 auto;
  position: relative;
  min-height: 100%;
}

.main {
  width: 100%;
  overflow: hidden;
  flex: 1 0 auto;
}

.content {
  width: 100%;
  padding-left: $space2;
  padding-right: $space2;
  margin-left: 0;
  margin-right: 0;

  @include sm {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    width: 100%;
  }
  @include lg {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    width: $lgMin;
  }
}

.content-home {
  width: 67.916666667%; //978
  margin-left: auto;
  margin-right: auto;
  @include md-max {
    width: 90%;
  }
  @include sm-max {
    width: 100%;
    padding: 0 0.9375rem;
  }
}

img {
  max-width: 100%;
}
