.jottaButton {
  text-transform: none;
  text-decoration: none;
  font-family: inherit;
  font-weight: $font-medium;
  letter-spacing: 0.03125rem;

  white-space: nowrap;
  vertical-align: middle;

  border: 1px solid;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
}

.jottaButton--small {
  font-size: 0.875rem;
  padding: 0.5625rem 0.5625rem 0.375rem;
}
.jottaButton--large {
  padding: 0.75rem 0.8125rem 0.625rem;
}

.jottaButton--primary {
  background-color: #59c150;
  border-color: #59c150;
  color: #fff;

  &__business {
    background-color: #6053bd;
    border-color: #6053bd;
    color: #fff;
  }
}

.jottaButton--link {
  background-color: #9c61e8;
  border-color: #9c61e8;
  color: #fff;
  padding-right: 1.4375rem;
  position: relative;

  &:before {
    content: '';
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 7px solid;
    opacity: 0.6;
    width: 0;
    height: 0;

    position: absolute;
    top: 50%;
    right: 0.6rem;
    transform: translateY(-50%);
  }
}

.jottaButton--outline {
  background-color: transparent;
  border-color: rgba(black, 0.15);
  &:hover {
    border-color: rgba(black, 0.25);
  }
}

.jottaButton--outline-invert {
  border-color: rgba(white, 0.4);
}

.jottaButton--block {
  display: block;
}

.jottaButton--standout {
  transition: transform 0.2s, box-shadow 0.2s;
  &:hover,
  &:focus {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(black, 0.1);
  }
}

.jottaButton-store {
  text-indent: -999rem;
  background-image: url(/resources/images/apps/apps.png);
  background-repeat: no-repeat;
  display: inline-block;
  height: 40px;
  width: 140px;
  overflow: hidden;
  @include hidpi {
    background-size: 140px 120px;
  }
}
span.jottaButton-store {
  opacity: 0.3;
}

.jottaButton-store--light {
  background-image: url(/resources/images/photos/apps.png);
}

.jottaButton-store--google {
  background-position: 0 -40px;
  @include hidpi {
    background-position: 0 -80px;
  }
}
.jottaButton-store--apple {
  background-position: 0 0;
  @include hidpi {
    background-position: 0 -40px;
  }
}

.button-store--light-small {
  transform: scale(0.875);
  transform-origin: right bottom;
}
