body.CC_Article.default-bg-image {
  background: white;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}
#site-wrapper {
  header.master-head {
    font-size: 12.8px;
    font-weight: normal;
    line-height: normal;
  }
  footer.master-foot {
    font-size: 12.8px;
    font-weight: normal;
    line-height: normal;
    .copyright {
      text-transform: none;
      &:before {
        content: none;
      }
    }
    a {
      text-decoration: underline;
    }
    .button {
      text-transform: none;
      border: 1px solid transparent;
      border-radius: 3px;
    }
  }
  #landingsside {
    font-family: sans-serif;
    font-weight: 300;
    font-size: 100%;
    line-height: 1.291666667;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    background-color: #f1f0f3;
    color: #45434c;
    min-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background: white;
    .button {
      line-height: inherit;
    }
  }
  .mini-product .info .name a,
  .link-list .top-menu-link,
  .link-list .sub-menu-link {
    font-size: 14.7712px !important;
  }
  .link-list .links {
    margin: 0;
    width: 100%;
  }
  .block-download-section {
    padding: 0 10px;
  }
  .article-page .article-free-html ul {
    margin-left: 0;
    padding-left: 0;
  }
}
