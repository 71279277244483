.hero {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 4.625rem;
  img {
    margin-top: 1.5rem;
    margin-bottom: 2.25rem;
    @include md-max {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @include sm-max {
    margin-top: 1.6875rem;
    margin-bottom: 3.125rem;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  &__title {
    color: #9c61e8;
    @include to-medium {
      padding-left: 10%;
      padding-right: 10%;
    }
    &-business {
      color: #6053bd;
    }

    &-family {
      color: #ff79ff;
    }

    &-features {
      color: #888;
      font-size: 2.875rem;
    }
  }

  &__sub-title {
    font-size: 0.875rem;
    font-weight: $font-regular;
    opacity: 0.6;
    margin: 0.9375rem 0 0;
    @include sm-max {
      display: none;
    }
  }

  &__text {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $space2;
    &-photo {
      margin-top: 1.25rem;
    }
  }

  &__button-group {
    padding-top: 2.1875rem;
    width: 100%;
    display: grid;
    grid-gap: $space1;
    @include to-medium {
      grid-template-columns: 1fr;
    }
    @include from-medium {
      grid-auto-columns: min-content;
      grid-template-rows: 1fr;
      grid-auto-flow: column;
      justify-content: center;
      padding-top: 2.75rem;

      .button-store {
        display: inline-block;
      }
    }
  }

  &__movie {
    margin-top: 2.75rem;
    .video-container {
      margin: 0 auto;
      max-width: 670px;
      #player {
        border: #cbcbcb 1px solid;
      }
      .flowplayer .fp-color {
        background-color: #9c61e8;
      }
      .flowplayer .fp-color-play {
        fill: #9c61e8;
      }
    }
    @include sm-max {
      margin-top: 2.1875rem;
    }
  }

  &--photos &__button-group {
    @include sm-max {
      &:first-of-type {
        display: none;
      }
      &:last-of-type {
        display: block;
      }
    }
  }

  &--family &__button-group {
    @include sm-max {
      &:first-of-type {
        display: none;
      }
      &:last-of-type {
        display: block;
      }
    }
  }
}
