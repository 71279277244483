.featured-in {
    border-top: 1px solid #eaeaea;
    padding-top: 4.375rem;
    margin-top: 9.375rem;
    padding-left: 12px;
    padding-right: 12px;

    &__heading {
        font-weight: 400;
    }
    
    &__row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 45px;
        margin-top: 90px;
        font-size: 22px;
        font-weight: 400;
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
        
        &__item {
            display: grid;
            grid-template-columns: max-content auto;
            grid-template-rows: auto min-content;
            gap: 0.5em 30px;
            text-align: left;
            align-items: center;
            transition: transform 200ms, filter 200ms;
            
            & img {
                object-fit: contain;
                flex: 0 0 auto;
                transition: filter 200ms;
            }

            & p {
                margin: 0;
                flex: 1 1 150px;
            }

            &__publisher {
                grid-row: 2;
                grid-column: 2;
                color: #464A4E;
                font-weight: 300;
                font-style: normal;
                place-self: end start;
            }
                    
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    transform: translateY(-2px);
                    filter: drop-shadow(0 8px 8px rgba(0, 0, 0, 0.1));
                    
                    img {
                        filter: brightness(1.25);
                    }
                }
            }
        }
    }

    @media (max-width: 1100px) {
        &__row {
            font-size: 19px;
        }
    }
    
    @media (max-width: #{$mdMax}) {
        align-items: center;
        width: 100%;
        margin-top: 1.875rem;
        padding-top: 1.875rem;
        
        &__row {
            margin-top: 45px;
            flex-direction: column;
            align-items: center;
            font-size: 19px;
            padding: 0 10px;
            max-width: 400px;

            &__item {
                grid-template-columns: 130px auto;

                & img {
                    place-self: center end;
                }
            }
        }
    }
}
