.download-section {
  margin-top: 12.5rem;
  padding-bottom: 3.75rem;
  @include sm-max {
    margin-top: 4.375rem;
    padding-bottom: 0;
  }
}

.download-text {
  font-size: 1.125rem;
}

.download {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.download__image {
  vertical-align: top;
  position: relative;
  z-index: 2;
}

.download__loader {
  position: absolute;
  z-index: 1;
  transform: translateY(100%);
  will-change: transform;
  animation: loading 2.5s infinite forwards linear;
}

.download--windows {
  background-color: #b990ee;
  border-radius: 4px;
  .download__loader {
    background-color: #fff;
    width: 100%;
    height: 100px;
    margin-top: -50px;
    top: 50%;
    left: 0;
  }
}

.download--mac {
  background-image: linear-gradient(-180deg, #f7f8fa 0%, #e5e9ef 100%);
  .download__loader {
    background-image: linear-gradient(-180deg, #9c61e8 0%, #fd7efc 100%);
    width: 90px;
    height: 90px;
    margin-left: -48px;
    margin-top: -43px;
    top: 50%;
    left: 50%;
  }
}

.section-main-apps {
  padding-top: 50px;
  text-align: center;
}

@keyframes loading {
  50%,
  100% {
    transform: translateY(-100%);
  }
}
