.family-promotion {
    text-align: center;
    padding-bottom: 10.5rem;
    padding-top: 10.5rem;
    max-width: 30rem;
    margin: 0 auto;
}

.family-promotion__header {
    font-size: 2rem;
    color: black;
    line-height: 2.625rem;
}