.logo {
  display: flex;
  align-items: center;
}

.logo-text {
  fill: currentColor;
  margin-left: 8px;
  height: 16px;
}

.logo-text--main {
  width: 97px;
}

.logo-text--photos {
  width: 158px;
}

.logo-symbol-large {
  width: 26px;
  height: 26px;
}
.logo-symbol {
  width: 23px;
  height: 23px;
}

.logo-main-color {
  .right {
    fill: #9c61e8;
  }
  .left {
    fill: #ff79ff;
  }
}
.logo-main-bw {
  path {
    fill: rgba(black, 0.5);
    transition: fill 0.2s;
  }
  &:hover path {
    fill: #000;
  }
}

.logo-photos-color {
  .top-left {
    fill: #9c61e8;
  }
  .top-right {
    fill: #ccb4ff;
  }
  .bottom-left {
    fill: #ff79ff;
  }
  .bottom-right {
    fill: #ffc3f8;
  }
}

.logo-photos-color--invert .top-left {
  fill: #fff;
}

.app-icon {
  background-color: transparent;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.app-icon--small {
  width: 120px;
  height: 120px;
  border: 1px solid rgba(black, 0.06);
  box-shadow: 0 0 20px rgba(black, 0.1);
  .logo-symbol-app {
    width: 86px;
    height: 86px;
  }
}
.app-icon--large {
  width: 125px;
  height: 125px;
  box-shadow: 0px 25px 50px 0px rgba(black, 0.5);
  .logo-symbol-app {
    width: 89px;
    height: 89px;
  }
  @include sm-max {
    width: 80px;
    height: 80px;
    .logo-symbol-app {
      width: 57px;
      height: 57px;
    }
  }
}
