.new-top-section {
    max-width: 900px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 22px;
    color: #2D2F32;
    font-size: 16px;

    & h1 {
        font-size: 48px;
        font-weight: 500;
        
        @include to-medium {
            font-size: 30px;
        }
    }

    & p {
        margin-top: 20px;
    }

}
