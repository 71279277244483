.app-hero {
  text-align: center;
  color: #fff;
  background-color: #9c61e8;
  background-image: linear-gradient(-180deg, #9c61e8 0%, #fd7efc 100%);
  padding-top: 10.625rem;
  margin-bottom: 6.25rem;
  position: relative;
  z-index: 1;
  @include sm-max {
    padding-top: 5.5rem;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    margin-bottom: 3.125rem;
  }
  &__text {
    max-width: 32.1875rem;
    margin-top: 0.75rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.5rem;

    @include sm-max {
      margin-bottom: 1.875rem;
      width: 80%;
    }
  }

  &__group {
    font-size: 0;
    &-item + &-item {
      margin-left: 3.125rem;
      @include sm-max {
        margin-left: 1.875rem;
      }
      @media (max-width: 21.5625em) {
        margin-left: 0.15rem;
      }
    }
  }

  &__images {
    margin-top: 4.375rem;
    @include sm-max {
      margin-top: 2.5rem;
    }
  }

  &__image {
    width: 23.333333333%;
    min-width: 8.875rem;
    box-shadow: 5px 0 20px rgba(black, 0.05);
    border-top-left-radius: 37.5px;
    border-top-right-radius: 37.5px;
    vertical-align: top;
    @include sm-max {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}
