$font-family: Larsseit, sans-serif;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;

$space: 0.9375rem;
$space1: calc($space / 2);
$space2: $space;
$space3: $space1 + $space2;
$space4: $space * 2;
$space5: $space * 3;
$space6: $space * 4;

$xsmin: 0px;
$smMin: 576px;
$mdMin: 768px;
$lgMin: 980px;
$xlMin: 1440px;
$xsMax: $smMin + 0.98;
$smMax: $mdMin + 0.98;
$mdMax: $lgMin + 0.98;
$lgMax: $xlMin + 0.98;
$contentPaddingLg: calc((100% - #{$lgMin}) / 2 + #{$space3});
$contentPaddingMd: calc((100% - #{$mdMin}) / 2 + #{$space3});
$contentPaddingSm: calc((100% - #{$smMin}) / 2 + #{$space3});

// Small devices
@mixin to-small {
  @media (max-width: #{$xsMax}) {
    @content;
  }
}
@mixin sm {
  @media (min-width: #{$smMin}) {
    @content;
  }
}
@mixin from-small {
  @media (min-width: #{$smMin}) {
    @content;
  }
}
@mixin sm-max {
  @media (max-width: #{$smMax}) {
    @content;
  }
}
@mixin to-medium {
  @media (max-width: #{$smMax}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$mdMin}) {
    @content;
  }
}
@mixin from-medium {
  @media (min-width: #{$mdMin}) {
    @content;
  }
}
@mixin md-max {
  @media (max-width: #{$mdMax}) {
    @content;
  }
}
@mixin to-large {
  @media (max-width: #{$mdMax}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$lgMin}) {
    @content;
  }
}
@mixin from-large {
  @media (min-width: #{$lgMin}) {
    @content;
  }
}
@mixin lg-max {
  @media (max-width: #{$lgMax}) {
    @content;
  }
}
@mixin to-xlarge {
  @media (max-width: #{$lgMax}) {
    @content;
  }
}

// Large devices
@mixin xl {
  @media (min-width: #{$xlMin}) {
    @content;
  }
}
@mixin from-xlarge {
  @media (min-width: #{$xlMin}) {
    @content;
  }
}

@mixin hidpi {
  @media (min-device-pixel-ratio: 1.3), (min-resolution: 120dpi) {
    @content;
  }
}

@mixin horizontalPadding {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;

  @include sm {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }
}

@mixin centeredContentLg {
  padding-left: $space3;
  padding-right: $space3;

  @include from-large {
    padding-left: $contentPaddingLg;
    padding-right: $contentPaddingLg;
  }
}
@mixin centeredContentMd {
  padding-left: $space3;
  padding-right: $space3;

  @include from-medium {
    padding-left: $contentPaddingMd;
    padding-right: $contentPaddingMd;
  }
}
@mixin centeredContentSm {
  padding-left: $space3;
  padding-right: $space3;

  @include from-medium {
    padding-left: $contentPaddingSm;
    padding-right: $contentPaddingSm;
  }
}
