.table-wrapper {
  display: flex;
}

.compare-table {
  display: block;
  border-collapse: collapse;
  margin: 24px auto;
  overflow-x: auto;

  thead th:first-of-type {
    border: unset;
  }

  tr td:nth-of-type(2) {
    background-color: rgba(156, 97, 232, 0.3);
  }


  th, td {
    padding: 12px;
    border: 1px solid #dee2e6;
    max-width: 180px;
    min-width: 120px;
  }

  .checked, .unchecked {
    padding: 0;
    padding-top: 8px;
  }

  .checked:before {
    content: '\2713';
    font-size: 22px;
    line-height: 12px;
    color: #218739;
  }

  .unchecked:before {
    content: '\2715';
    font-size: 20px;
    line-height: 12px;
    color: #dc3545;
  }

  .jottacloud {
    background-color: #9c61e8;
    color: white;
  }
}
