.feature-index {
  width: 100%;
  @include centeredContentLg;
  padding-top: $space6;
  padding-bottom: $space4;

  &:nth-child(2n + 2) {
    background-color: #fbfbfb;
  }

  &__header {
    padding-bottom: $space6;
  }

  &__heading {
    font-size: 2rem;
    @include from-medium {
      font-size: 2.7rem;
    }
  }

  &-columns {
    @include centeredContentLg;
    width: 100%;
    display: flex;

    font-size: 1.125rem;
    line-height: 2rem;
    text-align: left;
    @include to-medium {
      flex-flow: column nowrap;
      align-items: center;
    }
    @include from-medium {
      flex-flow: row wrap;
      justify-content: center;
    }
    p {
      margin-top: 0;
      margin-bottom: 2.5rem;
    }
    &__heading {
      font-size: 1.3125rem;
      font-weight: normal;
      margin: 0;
    }

    &__item {
      @include to-medium {
        width: 100%;
        max-width: 378px;
      }
      @include from-medium {
        padding-left: $space2;
        padding-right: $space2;
        width: 50%;
      }
    }
  }
}
