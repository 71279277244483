.new-site-footer {
  border-top: 0.0625rem solid #eaeaea;
  width: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  color: black;
  padding: 3.5rem;

  &__logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > span {
      padding-left: 0.25rem;
      margin-bottom: 0.25rem;
      color: #868F98;
      font-size: 1.25rem;
    }
  }
  .org-number {
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.03125rem;
    color: #868F98;
  }
  &__language {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .nav_container {
    display: flex;
    flex-wrap: wrap;
    @include from-large {
    flex-wrap: nowrap;
    justify-content: space-evenly;
    }
    @include md-max {
    position: relative;
    }
    @include sm-max {
     position: relative;
    }

  }
  .column_links {
    display: block;
    margin-top: 1rem;
    color: #868F98;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: .03125rem;
    transition: opacity 200ms;

    &:hover {
      color: #000;
    }
  }
  .links_column {
    margin-bottom: 1rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1rem;
    @include from-large {
      width: auto;
      margin-bottom: 0;
    }
  }
  .links_column-logo {
      display: none;
      @include from-large {
        display: block;
        padding-top: 1rem;
      }
  }

  .links_column-language-selector {
        display: block;

        @include md-max {
          position: absolute;
          bottom: -4.5rem;
          right: -1rem;
        }
        @include sm-max {
          position: absolute;
          bottom: -4.5rem;
          right: -3rem;
        }
  }
  .link_heading {
    color: #000;
    font-weight: 700;
    font-size: .875rem;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .bottomContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    align-items: center;

    @include from-large {
    display: none;
    }
  }
  .logoContainer {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
}
