.image-row {
  font-size: 0;
  overflow: hidden;
  position: relative;
  contain: strict;
  margin-top: 2rem;
}

.image-row--large {
  height: 394px;
  @include sm-max {
    height: 140px;
  }
}
.image-row--static {
  height: 150px;
  @include sm-max {
    height: 70px;
  }
}

.image-row__app-icon {
  margin-top: -62px;
  margin-left: -62px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  @include sm-max {
    margin-top: -40px;
    margin-left: -40px;
  }
}

.image-row__column {
  display: inline-block;
  white-space: nowrap;
  & + & {
    margin-top: 10px;
  }
  .image-row--static & {
    transform: translateX(-110px);
  }
  .image-row--animate & {
    animation: scroll 200s forwards;
    transform: translateX(-20px);
  }
  @include sm-max {
    & + & {
      display: none;
    }
    .image-row--animate & {
      animation: none;
    }
  }
}

.image-row__block {
  vertical-align: top;
  white-space: normal;
  display: inline-block;
  position: relative;
  contain: strict;
  &:not(:first-of-type) {
    margin-left: 10px;
    @include sm-max {
      margin-left: 4px;
    }
  }
}

.image-row__block--large {
  width: 288px;
  height: 192px;
  @include sm-max {
    width: 210px;
    height: 140px;
  }
}

.image-row__block--small {
  width: 225px;
  height: 150px;
  @include sm-max {
    width: 105px;
    height: 70px;
  }
}

.image-row__placeholder {
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #d9d9d9;
  transition: opacity 0.4s ease-in;
  .is-loaded & {
    opacity: 0;
  }
}

.image-row__image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

@keyframes scroll {
  from {
    transform: translateX(-20px);
  }
  to {
    transform: translateX(-1520px);
  }
}
