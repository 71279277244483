#cookieConsent {
  background: rgba(0, 0, 0, 0.8);
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  z-index: 300;
  display: flex;
  &.is-accepted {
    display: none;
  }
  .cookieInfo {
    display: table-cell;
    width: 90%;
    font-size: 1.5rem;
  }
  .cookieButtons {
    display: table-cell;
    text-align: left;
  }
  .cookieButton {
    margin: 0 10px 10px 10px;
    white-space: nowrap;
  }
}

// body.hideIntercom #intercom-container,
// body.hideIntercom .custom-intercom-button {
//   // display: none;
// }
