.nav {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;

  @include md-max {
    flex-wrap: wrap;
    justify-content: center;
  }
  @include md {
    justify-content: center;
  }

  .badge {
    margin-left: 0.3125rem;
  }

  &__list-item {
    white-space: nowrap;
    transition: color 0.2s;
    > a {
      padding: 0.5em 1em;
    }
    &:hover > a {
      color: #000;
    }
    .site-header--invert &:hover {
      color: rgba(white, 0.8);
    }
    &--left {
      margin-right: auto;
    }
    &--right {
      margin-left: auto;
    }
    &--button {
      margin-left: 0.875rem !important;
    }
    &--hide-large {
      display: none;
      @include sm-max {
        display: block;
      }
    }
    &--hide-small {
      @include sm-max {
        display: none;
      }
    }
  }
}
