.list-style {
  margin: 0 0 0.5rem;
  word-break: break-word;
  display: table;
  content: " ";
  font-size: 1.125rem;
}

.list-style-dt {
  font-weight: 700;
  padding-right: 0.625rem !important;
  clear: left;
  display: inline-block;
  float: left;
  margin: 0 0 0.625rem;
  padding: 0;
}

.list-style-dd {
  display: inline-block;
  float: left;
  margin: 0 0 0.625rem;
  padding: 0;
}

.jobs__title {
  font-size: 1.75rem;
  line-height: 2.125rem;
  font-weight: 700;
  margin: 0 0 2rem;
  text-align: left;
}

.bold-text {
  font-weight: 700 !important;
}

.jobs_title2 {
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.75rem;
  margin: 0 0 0.5rem;
}

.ul {
  list-style: none;
}
.li::before {
  color: #9c61e8;
  content: "\2022";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

