.pricing-table {
  font-weight: $font-regular;
  display: inline-flex;
  max-width: 100%;
  gap: $space4;
  padding: 12px;
  justify-content: center;
  overflow-x: auto;
  flex-flow: row wrap;
  margin: 0 auto;
  grid-auto-columns: minmax(20%, 33%);
  grid-auto-flow: column;

  &__heading {
    font-size: 1.625rem;
  }

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &__heading {
    font-weight: 300;
    margin: 0;

    &--unlimited {
      color: #9c61e8;
    }

    &--family {
      color: #ff79ff;
    }

    &--business-small,
    &--business-medium {
      color: #6053bd;
    }

    &--business-large {
      color: #6053bd;
    }
  }

  &__item {
    margin-bottom: #{$space2};
    flex: 0 0 auto;
    width: 300px;
    background-color: #fff;
    border: 1px solid rgba(black, 0.1);
    border-radius: 4px;
    height: 34rem;
    padding: 2.5625rem 1.25rem 1.25rem;
    margin-bottom: 1.3125rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: transform 0.2s;
    overflow: hidden;
    z-index: 1;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 20px rgba(black, 0.1);
    }

    &.popular::before {
      color: white;
      font-weight: 400;
      font-size: 13px;
      position: absolute;
      width: 130px;
      height: 22px;
      line-height: 24px;
      background: #59c150;
      top: 27px;
      right: -27px;
      transform: rotate(45deg);
      text-transform: uppercase;
    }
  }

  &-price {
    display: inline-block;
    padding-left: 17px;
    padding-left: calc(1ch + 7px);
    position: relative;

    &__currency {
      font-size: 1.5rem;
      color: #59c150;
      position: absolute;
      top: 6px;
      left: -12px;
    }

    &__amount {
      font-size: 3rem;
      color: #59c150;
    }

    &__period {
      font-size: 0.9375rem;
      opacity: 0.4;
      &:before {
        content: '\2f';
      }
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__feature {
    text-align: left;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.pricing--business .pricing-table {
  //   @include lg {
  //     padding-left: 0.9375rem;
  //     padding-right: 0.9375rem;
  //     padding-bottom: 0;
  //     max-width: 25.5rem;
  //   }
  //   &__row {
  //     @include lg {
  //       width: 100%;
  //       &:not(:first-of-type) {
  //         margin-left: 0;
  //         margin-top: 0.9375rem;
  //       }
  //       &:last-of-type {
  //         margin-bottom: 6.25rem;
  //       }
  //     }
  //   }
  &__storage {
    font-size: 1.125rem;
    opacity: 0.5;
  }
}

.hide-price-row {
  display: none;
}

.plan-features {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 0 5px;
  height: 200px;
}
.plan-features__title {
  margin-left: 6px;
  margin-bottom: 15px;
  font-weight: 500;
}
.plan-feature {
  font-size: 14px;
  font-weight: normal;
  color: #45434c;
  display: flex;
  margin-bottom: 10px;
  //line-height: 18px;
}
.plan-feature > svg {
  transform: translateY(2px);
  margin-right: 10px;
  color: #59c150;
}

.plan-note {
  font-size: 13px;
  font-weight: normal;
  color: #888888;
  display: flex;
  margin-bottom: 10px;
  margin-top: 15px;
  line-height: 18px;
}

.plan-sep-line {
  border-bottom: 1px solid rgba(black, 0.1);
  margin: 10px 0;
}

.plan-list-item {
@include from-large {
    width: 7.75rem;
    word-wrap: break-word;
  }
}

.price-wrapper {
  display: flex;
  justify-content: center;
}

.business-price {
  padding-left: 0.75rem;
  position: relative;
}

.business-amount {
  font-size: 2.75rem;
  color: #59c150;
}

.plan-features-home {
  @include from-large {
  height: 240px;
  }
}



