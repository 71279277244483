.download-hero {
  text-align: center;
  color: #fff;
  background-color: #9c61e8;
  background-image: linear-gradient(-180deg, #9c61e8 0%, #fd7efc 100%);
  padding-top: 8rem;
  padding-bottom: 8rem;
  margin-bottom: 6.25rem;
  position: relative;
  z-index: 1;
  @include sm-max {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    margin-bottom: 3.125rem;
  }
}

.download-hero__text {
  max-width: 32.1875rem;
  margin-top: 0.75rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;

  @include sm-max {
    margin-bottom: 1.875rem;
    width: 80%;
  }
}
