a {
  color: inherit;
  text-decoration: none;
}

.text {
  font-size: 1.5rem;
  margin: 0;
  @include md-max {
    font-size: 1.3125rem;
  }
  @include sm-max {
    font-size: 1.1875rem;
  }
}

.italic {
  font-style: italic;
}

address {
  font-style: normal;
  color: #45434c;
}

.text--muted {
  opacity: 0.7;
}

.small {
  font-size: 0.875rem;
  font-weight: $font-medium;
  letter-spacing: 0.03125rem;
}

.smaller {
  font-size: 1.25rem;
  @include md-max {
    font-size: 1.1rem;
  }
  @include sm-max {
    font-size: 1rem;
  }
}

.smallest {
  font-size: 0.875rem;
  @include md-max {
    font-size: 0.75rem;
  }
  @include sm-max {
    font-size: 0.6rem;
  }
}

.link {
  white-space: nowrap;
  &:after {
    content: '\3e';
    margin-left: 0.3rem;
    display: inline-block;
    transition: transform 0.2s;
  }
  @include sm-max {
    display: inline-block;
  }
  &:hover {
    &:after {
      transform: translateX(2px);
    }
  }
}

.videolink {
  margin-left: 2rem;
  cursor: pointer;
  white-space: nowrap;
  &:after {
    content: '\3e';
    margin-left: 0.3rem;
    display: inline-block;
    transition: transform 0.2s;
  }
  @include sm-max {
    display: inline-block;
  }
  &:hover {
    &:after {
      transform: translateX(2px);
    }
  }
}

.link-reverse {
  &:before {
    content: '\3c';
    margin-right: 0.3rem;
    display: inline-block;
    transition: transform 0.2s;
  }
  &:hover {
    &:before {
      transform: translateX(-2px);
    }
  }
}

.link--purple {
  color: #9c61e8;
  &:hover {
    color: #8449ce;
  }
}
.link--pink {
  color: #ffc3f7;
}

h1,
h2 {
  font-weight: $font-light;
  margin: 0;
}

.alpha {
  font-size: 4.5rem !important;
  line-height: 1.1;
  letter-spacing: -0.0625rem;
  @include sm-max {
    font-size: 2.625rem !important;
  }
}

.beta {
  font-size: 2rem;
  @include md-max {
    font-size: 1.5rem;
  }
}

.gamma {
  font-weight: $font-regular;
}
