.feature-compare {
  margin-bottom: 0;
}

.section-columns-compare {
  margin-bottom: 1rem;
}

.table-title {
  color: #9c61e8;
}
