.container {
  display: flex;
  text-align: start;
  justify-content: space-between;
  background-color: #fbfbfb;
  @include md-max {
    display: block;
  }
  }

  .container2 {
    background-color: white;
    flex-direction: row-reverse;
  }

  .wrapper {
    margin: 2rem;
  }

  .wrapper2 {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .imagedownload1 {
    height: auto;
    width: 170rem;
    object-fit: contain;
  }

.imagedownload2 {
  width: 32rem;
}

.imagedownload3 {
  width: 74rem;
}

.heading1 {
  color: #9c61e8;
  font-size: 4rem;
  margin: 2rem 1rem;
  text-align: center;
  @include md-max {
    font-size: 2.5rem;
  }
}

.heading2 {
 margin-top: 2rem;
}

.listStyle {
  font-size: 1.5rem;
  list-style-type: disc;
  padding-inline-start: 1.5rem;
  margin-block-start: 0;
  @include md-max {
    font-size: 1.1875rem;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
  @include md-max {
    flex-direction: column;
  }
}

.buttonWrapperMacOs {
margin-top: 2rem;
margin-right: 1rem;
margin-bottom: 2rem;

@include md-max {
  margin-right: 0;
  margin-bottom: 1rem;
}
}

.buttonWrapperWindows {
margin-top: 2rem;
margin-left: 1rem;
margin-bottom: 2rem;

@include md-max {
  margin-top: 1rem;
  margin-left: 0;
}
}

.ndc-beta-download-paragraph {
  opacity: 0.7;
  margin: 1rem 0;
  font-size: 1.5rem;
  color: #45434c;
  @include md-max {
    font-size: 1.1875rem;
  }
}

