.menu-button {
  color: inherit;
  background: none;
  border: 0;
  width: 17px;
  height: 14px;
  padding: 0;
  z-index: 5;
  cursor: pointer;
  position: relative;
  @include to-large {
    position: fixed;
    left: initial;
    top: initial;
    &.is-closed {
      position: absolute;
    }
  }
  &:focus {
    outline: 0;
  }
  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 2px;
    transform-origin: center;
    background-color: currentColor;
    position: absolute;
    left: 0;
    animation-delay: 0.2s;
    animation-duration: 0.35s;
    animation-fill-mode: forwards;
  }
  &:before {
    top: 0;
    animation-name: menu-top;
  }
  &:after {
    bottom: 0;
    animation-name: menu-bottom;
  }

  > span {
    width: 100%;
    height: 2px;
    background-color: currentColor;
    display: block;
    opacity: 0;
    transition: opacity 0.25s;
  }

  &.is-closed {
    > span {
      opacity: 1;
    }
    transition: opacity 0.25s 0.4s;
    &:before,
    &:after {
      animation-duration: 0.35s;
      animation-fill-mode: forwards;
    }
    &:before {
      animation-name: menu-top-reverse;
      transform: translateY(6px) rotate(45deg);
    }
    &:after {
      animation-name: menu-bottom-reverse;
      transform: translateY(-6px) rotate(-45deg);
    }
  }
}

@keyframes menu-top {
  0% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(6px);
  }
  100% {
    transform: translateY(6px) rotate(45deg);
  }
}

@keyframes menu-bottom {
  0% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(-6px) rotate(-45deg);
  }
}

@keyframes menu-top-reverse {
  0% {
    transform: translateY(6px) rotate(45deg);
  }
  15% {
    transform: translateY(6px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes menu-bottom-reverse {
  0% {
    transform: translateY(-6px) rotate(-45deg);
  }
  15% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0);
  }
}
