.about {
  text-align: center;
  color: #45434c;
  max-width: 45.375rem;
  width: 100%;
  margin: 9.375rem auto 12.5rem;
  a {
    color: #9c61e8;
  }
  @include sm-max {
    margin: 5rem 0;
  }
}

.about__image {
  margin-bottom: 9.375rem;
  @include sm-max {
    max-width: 50%;
    margin-bottom: 5rem;
  }
}

.about__title {
  color: #000000;
  margin-bottom: 0.625rem;
}

.about__section {
  text-align: left;
  margin-top: 5rem;
  > p {
    margin-bottom: 2.125rem;
  }
  &:first-of-type {
    margin-top: 0;
  }
  @include sm-max {
    margin-top: 3rem;
  }
}

.about__section--div {
  font-size: 0;
}

.about__part {
  font-size: 1rem;
  display: inline-block;
  vertical-align: top;
  width: 47.933884298%;
  margin-top: 2.5rem;
  margin-left: 4.132231404%;
  &:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  &:nth-of-type(2n + 1) {
    margin-left: 0;
  }
  @include sm-max {
    width: 100%;
    margin-left: 0;
    margin-top: 1.5rem;
    &:nth-of-type(2) {
      margin-top: 1.5rem;
    }
  }
}

.about__part--space {
}
/*
.list__item--single:nth-last-child(-n+3):nth-child(3n+1):before,
.list__item--single:nth-last-child(-n+3):nth-child(3n+1)~.list__item--single:before*/
