.site-header {
  flex: 0 0 auto;
  width: 100%;
  font-weight: $font-regular;
  padding: 1.375rem 7.222222222% 1.125rem;
  display: grid;
  align-items: center;

  &--invert {
    color: #fff;
  }

  &--floating {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &__logo {
    display: flex;
    align-items: center;
  }

  &--invert &__nav {
    background-color: #45434c;
  }
  @include to-large {
    grid-template-columns: 100fr 1fr;
    &__hamburger {
      position: relative;
    }
    &.is-expanded &__nav {
      opacity: 1;
      z-index: 4;
      backdrop-filter: blur(5px);
      &__section {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  @include from-large {
    grid-template-columns: 1fr 100fr;
    &__hamburger {
      display: none;
    }
  }

  &__nav {
    text-transform: none;
    font-size: 16px;
    z-index: 4;

    // Below 980
    @include to-large {
      transition: opacity 300ms, transform 300ms;
      background-color: rgba(black, 0.3);
      opacity: 0;
      z-index: -1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      &__section {
        transition: opacity 300ms, transform 300ms;
        transform: translateY(-100vh);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: $space4;
        padding-right: $space4;
        background-color: #fff;

        > a {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex: 0 0 auto;
          opacity: 0.85;
          transition: opacity 200ms;
          white-space: nowrap;
          padding: $space2;

          &:hover {
            opacity: 1;
          }
        }
        &:first-child {
          padding-top: $space4;
          box-shadow: 0 1px 0 0 rgba(black, 0.2);
        }
        &:last-child {
          padding-bottom: $space4;
          box-shadow: 0 1px 0 0 rgba(black, 0.2);
        }
      }
    }
    // Above 980
    @include from-large {
      width: 100%;
      flex: 1 1 100%;
      display: flex;
      flex-direction: row;
      padding-left: $space2;

      &__section {
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;
        align-items: center;

        &__link {
          &.is-aligned-right {
            margin-left: auto;
          }
          display: block;
          flex: 0 0 auto;
          opacity: 0.85;
          transition: opacity 200ms;
          white-space: nowrap;
          padding-left: $space2;
          padding-right: $space2;
          transform: translateY(2px);

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
