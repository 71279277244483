.faq {
  text-align: center;
  padding-bottom: 10.5rem;
  padding-top: 10.5rem;
  @include sm-max {
    padding-top: 5.5rem;
    padding-bottom: 2rem;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
  &__header {
    margin-bottom: 5rem;
    padding-top: 5rem;
    @include sm-max {
      margin-bottom: 2.5rem;
      padding-top: 1.875rem;
    }
  }

  &__heading {
    font-weight: 300;
    font-size: 2em;
    margin-bottom: 0.625rem;
    @include sm-max {
      font-size: 1.5rem;
    }
  }

  &-columns {
    @include centeredContentLg;
    width: 100%;
    display: flex;

    font-size: 1.125rem;
    line-height: 2rem;
    text-align: left;
    @include to-medium {
      flex-flow: column nowrap;
      align-items: center;
    }
    @include from-medium {
      flex-flow: row wrap;
      justify-content: center;
    }
    p {
      margin-top: 0;
      margin-bottom: 2.5rem;
    }
    &__heading {
      font-size: 1.3125rem;
      font-weight: normal;
      margin: 0;
    }

    &__item {
      @include to-medium {
        width: 100%;
        max-width: 378px;
      }
      @include from-medium {
        padding-left: $space2;
        padding-right: $space2;
        width: 50%;
      }
    }
  }
}
