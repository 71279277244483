.block-safe {
  background-color: #5d3b7d;
  background-image: linear-gradient(to bottom, #56387b, #673f80);
  color: #fff;
  height: 332px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  .section-heading {
    color: inherit;
  }
  @include md-max {
    background-image: linear-gradient(to bottom, #7a4eb4, #b860c2);
  }
  @include sm-max {
    height: auto;
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }

  &__body {
    text-align: left;
    padding-left: 51.859956236%;
    position: relative;
    z-index: 2;
    @include md-max {
      padding-left: 9%;
      padding-right: 9%;
    }
    @include sm-max {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__patterns {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    @include sm-max {
      display: none;
    }
  }

  &__pattern {
    &-normal {
      background-image: url('/resources/images/photos/pattern.png');
      background-repeat: repeat-x;
      @include hidpi {
        background-image: url('/resources/images/photos/pattern@2x.png');
        background-size: 182px 330px;
      }
      &--left {
        background-position: top right;
        width: 27.569444444%;
      }
      &--right {
        background-position: top left;
        flex: 1;
      }
    }
    &-active {
      background-image: url('/resources/images/photos/pattern-active.png');
      background-repeat: no-repeat;
      width: 182px;
      @include md-max {
        display: none;
      }
      @include hidpi {
        background-image: url('/resources/images/photos/pattern-active@2x.png');
        background-size: 168px 330px;
      }
    }
  }
}

.block-company {
  text-align: center;
  background: #9c61e8;
  background-image: linear-gradient(-180deg, #764cac 0%, #a65ab6 100%);
  color: #fff;
  height: 542px;
  display: flex;
  position: relative;
  @include md-max {
    height: auto;
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  &__background {
    stroke: currentColor;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    @include md-max {
      top: -50px;
    }
    @include sm-max {
      top: -70px;
    }
  }

  &__content {
    margin: auto;
    width: 33.888888889%;
    position: relative;
    z-index: 2;
    @include md-max {
      width: 50%;
    }
    @include sm-max {
      width: 100%;
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
    }
  }

  &__icon {
    width: 123px;
    height: 91px;
    margin-bottom: 3.125rem;
    @include sm-max {
      margin-bottom: 1.875rem;
    }
  }

  &__button {
    margin-top: 2.5rem;

    @include sm-max {
      display: block;
      width: 100%;
      margin-top: 1.875rem;
    }
  }
}

.block-businesses {
  text-align: center;
  background: #9c61e8;
  background-image: linear-gradient(-180deg, #764cac 0%, #a65ab6 100%);
  color: #fff;
  padding: 5rem 0 5.4375rem;
  @include sm-max {
    padding: 2rem 0;
  }
}
.contact-list {
  padding: 0;
  margin-top: 2.5rem;
  margin-bottom: 0;
  list-style: none;
  display: inline-block;
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
  @include sm-max {
    margin-top: 2rem;
  }
  &__item {
    display: flex;
    align-items: center;
    float: left;
    & + & {
      margin-left: 3.125rem;
    }
    @include sm-max {
      text-align: center;
      float: none;
      & + & {
        margin-top: 1.5rem;
        margin-left: 0;
      }
    }
  }

  &__icon {
    margin-right: 0.625rem;
    margin-top: -0.4rem;
  }
  &__icon--mail {
    width: 29px;
    height: 21px;
  }
  &__icon--phone {
    width: 20px;
    height: 32px;
  }
}
.block-download {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
  text-align: center;
}
.hero + .block-download {
  padding-top: 0;
}
.block-download-content {
  display: inline-block;
}
.block-download-section {
  display: inline-block;
  padding: 0 10px;
  text-align: left;
}
.block-apps {
  color: #000;
  text-align: center;
  background-color: #fbfbfb;
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
  @include sm-max {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.block-apps__list {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
  display: inline-block;
}

.block-apps__list-item {
  float: left;
  margin-left: 1.5rem;
  &:first-of-type {
    margin-left: 0;
    margin-top: 0;
  }
  @include sm-max {
    float: none;
    margin-left: 0;
    margin-top: 0.5rem;
  }
}

.block-apps__text {
  opacity: 0.5;
  margin-top: 0.4375rem;
  margin-bottom: 3.125rem;
}

.block-apps__list-icon {
  text-indent: -999rem;
  background-image: url(/resources/images/download/download.jpg);
  height: 40px;
  width: 140px;
  display: inline-block;
  overflow: hidden;

  @include hidpi {
    background-size: 140px 180px;
  }
}

.block-apps__list-icon-nb {
  background-image: url(/resources/images/download/download_no.jpg);
}

.block-apps__list-icon-da {
  background-image: url(/resources/images/download/download_dk.jpg);
}

.block-apps__list-icon-fi {
  background-image: url(/resources/images/download/download_fi.jpg);
}

.block-apps__list-icon-is {
  background-image: url(/resources/images/download/download.jpg);
}

.block-apps__list-icon-sv {
  background-image: url(/resources/images/download/download_se.jpg);
}

.block-apps__list-icon--apple {
  background-position: 0 -240px;
  @include hidpi {
    background-position: 0 0;
  }
}
.block-apps__list-icon--google {
  background-position: 0 -280px;
  @include hidpi {
    background-position: 0 -40px;
  }
}
.block-apps__list-icon--microsoft {
  background-position: 0 -320px;
  width: 110px;
  @include hidpi {
    background-position: 0 -80px;
  }
}
.block-download {
  color: #000;
  text-align: center;
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
  @include sm-max {
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.block-download__gray {
  background-color: #fbfbfb;
}

.block-download__list {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
  display: inline-block;
}

.block-download__list-item {
  float: left;
  margin-left: 1.5rem;
  &:first-of-type {
    margin-left: 0;
    margin-top: 0;
  }
  .jottaButton {
    width: 100%;
  }
  @include sm-max {
    float: none;
    margin-left: 0;
    margin-top: 2rem;
  }
}

.block-download__text {
  opacity: 0.5;
  margin-top: 0.4375rem;
  margin-bottom: 3.125rem;
}
.block-download__list-container {
  display: inline-block;
  .jottaButton {
    display: block;
  }
}
.block-download__list-icon {
  height: 4rem;
  width: 9.75rem;
  display: block;
  margin: 0 auto;
  margin-bottom: 2.5rem;
  //padding-left:2.875rem;
  //padding-right:2.875rem;
  svg {
    height: 100%;
    width: auto;
    fill: #5f52bd;
  }
}

.block-download__list-inline {
  display: inline-block;
}
