.headingThree {
    text-transform: uppercase;
    text-decoration: underline;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-bottom: 1rem !important;
    font-weight: 500 !important;
}

.headingThreeV3 {
@extend .headingThree;
text-decoration: none;
}

.headingThreeItalic {
    font-style: italic;
    font-weight: 300 !important;
}

.pItalic {
font-style: italic;
}

