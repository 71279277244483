.section-main {
  text-align: left;
  width: 37.375rem;
  max-width: 100%;
  margin: 0 auto;
  @include sm-max {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}

.section-backup {
  text-align: left;
  width: 58%;
  @include md-max {
    flex-grow: 1;
  }
  @include sm-max {
    width: 100%;
  }
}

.section-app {
  display: flex;
  &:not(:first-of-type) {
    margin-top: 6.25rem;
    @include sm-max {
      margin-top: 3.125rem;
    }
  }
}

.section__aside {
  text-align: center;
  width: 30.853391685%;
  @include sm-max {
    display: none;
  }
}

.section__body {
  width: 65.426695842%;
  margin-left: 3.719912473%;
  @include sm-max {
    width: 100%;
    margin-left: 0;
  }
}

.section-heading {
  color: #000;
  display: flex;
  align-items: flex-start;
}

.section-heading__icon {
  width: 25px;
  height: 25px;
  margin-right: 0.75rem;
  transform: translateY(4px);
  flex-shrink: 0;
  @include md-max {
    transform: translateY(2px);
  }
  @include sm-max {
    width: 18px;
    height: 18px;
    margin-right: 0.5rem;
  }
}

.section-heading__badge {
  margin-top: 6px;
  margin-right: 10px;
  @include md-max {
    margin-top: 2.5px;
  }
}

.section-heading__title {
  margin-bottom: 0.625rem;
  @include sm-max {
    margin-bottom: 0.1rem;
  }
}

.section__button {
  margin-top: 1.5625rem;
}

.section-home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8.75rem;
  text-align: left;
  @include md-max {
    margin-bottom: 5rem;
  }
  @include sm-max {
    flex-direction: column-reverse;
    margin-bottom: 4.375rem;
  }
}

.section-home--reverse {
  flex-direction: row-reverse;
  @include sm-max {
    flex-direction: column-reverse;
  }
}

.section-home__body {
  width: 61.349693252%;
  @include md-max {
    width: 50%;
  }
  @include sm-max {
    width: 100%;
  }
}

.section-home__media {
  text-align: center;
  width: 35.582822086%;
  @include md-max {
    width: 45%;
  }
  @include sm-max {
    width: 100%;
    margin-bottom: 0.625rem;
  }
}

.smart-search-image {
  height: auto;
  width: 20rem;
  object-fit: contain;
  @include sm-max {
    margin-bottom: 0.625rem;
  }
}

.section-columns {
  display: flex;
  flex-flow: row wrap;
  font-size: 1.125rem;
  line-height: 2rem;
  text-align: left;
  //max-width: 60rem;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 5rem;
  justify-content: center;
  align-items: flex-start;
  justify-content: space-between;
  p {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }
  @include md-max {
    justify-content: center;
  }
}

.section-columns__heading {
  font-size: 1.3125rem;
  font-weight: normal;
  margin: 0;
}

.section-columns__item {
  display: inline-block;
  width: 26rem;
  /*
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      margin: 0;
      */
  @include md-max {
    width: 100%;
  }
}

.standout {
  text-align: center;
  margin-bottom: 10.625rem;
  @include md-max {
    margin-bottom: 5rem;
  }
  @include sm-max {
    display: none;
  }
}
.standout__title {
  margin-bottom: 1.875rem;
}

.section-pricing__body {
  text-align: left;
}
