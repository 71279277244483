.tour-section {
    width: 100%;
    margin: 0 auto;
    padding: 75px 0;
    background: #FFF;
}
.tour-section .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 768px) {
    .tour-section .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .tour-section .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .tour-section .container {
        width: 960px;
    }
}
.tour-section .tour-heading h2 {
    font-size: 24px;
    font-weight: 400;
    margin: 0 auto;
    color: #364B5A;
    line-height: 27px;
        text-align: center;
}
.tour-section .tour-heading h3 {
    font-size: 18px;
    font-weight: 300;
    margin: 0 auto;
    color: #7C8D99;
    line-height: 27px;
        text-align: center;
}
.tour-section .carousel-wrapper {
    height: 280px;
    margin-top: 20px;
}
.tour-section .carousel {
    position: relative;
}
.tour-section .carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.tour-section .carousel-inner > .item {
    position: relative;
}
.tour-section .carousel-block {
    height: 280px;
    padding: 0px 10px;
}
.tour-section .carousel-inner > .active {
    left: 0;
}

.tour-section .carousel-animation {
    float: left;
    width: 50%;
    height: 100%;
    overflow: hidden;
}
.tour-section .carousel-block .video-wrapper {
    position: relative;
    height: 100%;
}
.tour-section .carousel-block img.video {
    display: none !important;
    width: 80%;
}
.tour-section .carousel-block .video {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: relative;
    margin: 0 auto;
    display: block;
        max-width: 100%;
        height: auto;
        vertical-align: baseline;
        object-fit: contain;
}
.tour-section .carousel-controls button {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
        width: 49%;
    background: #f2f4f6 none;
    font-weight: 300;
        height: 54px;
}
.tour-section .carousel-controls button.carousel-prev {
    visibility: hidden;
}
.tour-section .carousel-controls button:last-child {
    margin-right: -1%;
}
.tour-section .carousel-block h5 {
    color: #929699;
    font-weight: 300;
    font-size: 18px;
    text-align: left;
    margin-top: 11px;
        margin-bottom: 11px;
            line-height: 1.1;
}
.tour-section .carousel-block h6 {
    text-align: left;
    font-size: 24px;
        font-weight: 400;
        line-height: 1.1;
        margin-top: 11px;
            margin-bottom: 11px;

}
.tour-section .carousel-block p {
    font-weight: 300;
    margin: 0 0 11px;
}
.tour-section .carousel-text {
    display: table;
    height: 100%;
    width: 50%;
}
.tour-section .carousel-text .wrapper {
    display: table-cell;
    vertical-align: middle;
}
.tour-section .carousel-controls button span {
    color: rgba(0, 0, 0, 0.25);
    vertical-align: middle;
    padding: 0px 8px;
    line-height: 14px;
    font-size: 10px;
    margin-top: 0px;
}
.tour-section .carousel-controls button:hover, .tour-section .carousel-controls button:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
}
.tour-section .carousel-controls button:focus {
    outline-offset: -2px;
}
.tour-section .swipe {
  overflow: hidden;
  visibility: hidden;
  position: relative;
}
.tour-section .swipe-wrap {
  overflow: hidden;
  position: relative;
}
.tour-section .swipe-wrap > div {
  float:left;
  width:100%;
  position: relative;
}
.tour-section .carousel-controls button:active, .tour-section .carousel-controls button:focus {
    color: #000;
    background-color: #DBE1E4;
    outline: 0;
    background-image: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}