
.icon-container {
  display: flex;
  margin-top: 3.75rem;
  gap: 1rem;
  @include sm-max {
    flex-direction: column;
  }
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 25%;
  @include sm-max {
    width: 100%;
  }
}

.icon-wrapper h3 {
  white-space: nowrap;
}

.icon-wrapper p {
  font-size: 1.125rem;
  line-height: 2rem;
  margin-bottom: 2rem;
}

.icon-wrapper svg {
  margin-bottom: 1rem;
}

.smart-photo__icon {
  width: 10rem;
  height: 10rem;
  @include sm-max {
    width: 9.5rem;
    height: 9.5rem;
  }
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
  margin-bottom: 3.75rem;
  @include sm-max {
    width: 100%;
  }
}

.list > li {
  text-align: left;
  margin: 1rem 0 0 0;
}

.list > li::marker {
  font-weight: bold;
  text-align: center;
}

.list > li p {
  font-size: 1.125rem;
  line-height: 2rem;
  margin-top: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.smart-photo-btn-container {
display: flex;
justify-content: center;
align-items: center;
gap: 1.5rem;
flex-wrap: wrap;
margin-bottom: 3.75rem;
@include sm-max {
flex-direction: column;
}
}

.container-style {
  margin-bottom: 0;
}

.p-smart-photo {
  margin-bottom: 1.25rem;
  text-align: left;
}

.p-style {
  padding-left: calc((100% - 980px)/2 + 1.40625rem);
  padding-right: calc((100% - 980px)/2 + 1.40625rem);
}

.smart-search-container {
  padding-top:0;
  margin-top: 1.75rem;
}

.smart-search__gray:nth-child(2n + 2) {
  background-color: #F3F3F3;
}

.smart-photo-link {
align-items: center;
white-space: inherit;
display: flex;
@include sm-max {
  flex-direction: column-reverse;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
@media (hover: hover) and (pointer: fine) {
&:hover {
transition: transform 200ms, filter 200ms;
transform: translateY(-2px);
filter: drop-shadow(0 8px 8px rgba(0, 0, 0, 0.1));
    }
  }
}

.read-more__link {
  white-space: inherit;
  @media (hover: hover) {
    &:hover {
    transition: transform 200ms, filter 200ms;
    transform: translateY(-2px);
    filter: drop-shadow(0 8px 8px rgba(0, 0, 0, 0.1));
        }
      }
}







