.feature {
  text-align: center;
  margin-bottom: 9.375rem;
  @include sm-max {
    margin-bottom: 3.75rem; // 60
  }
}

.feature__image {
  margin-bottom: 3.75rem; //60

  @include md-max {
    margin-bottom: 2.5rem;
  }
  @include sm-max {
    margin-bottom: 1.25rem;
  }
}
picture.feature__image {
  display: inline-block;
}
img.feature__image {
  max-width: 916px;
  @include md-max {
    width: 80%;
  }
  @include sm-max {
    width: 85%;
  }
}

.feature__image--shadow {
  box-shadow: 0 0 50px rgba(black, 0.1);
  border-radius: 4px;
}

.feature-backup {
  margin-top: 9.375rem;
  display: flex;
  @include md-max {
    padding-left: 9%;
    padding-right: 9%;
  }
  @include sm-max {
    margin-top: 2.8125rem;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    display: block;
  }
}

.feature-backup__aside {
  text-align: center;
  flex-grow: 1;
  @include sm-max {
    display: none;
  }
}

@include md-max {
  .feature-backup__icon {
    max-width: 150px;
  }
}
