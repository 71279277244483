.blog {
  width: 100%;
  @include centeredContentMd;
  margin-top: 3.75rem;
  text-align: left;
}

.blog-title {
  color: #9c61e8;
  margin-bottom: 3.75rem;
}

.blog__post {
  margin-bottom: 12.5rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  p,
  ul,
  ol,
  img {
    margin-bottom: 1.625rem;
  }
  @include sm-max {
    margin-bottom: 3rem;
  }
}

.blog__title {
  font-weight: $font-light;
  margin-bottom: 0.3125rem;
  margin-top: 0;
}

.blog__time {
  font-size: 1.125rem;
  display: block;
  margin-bottom: 1.25rem;
}

.blog__body {
  p,
  ul,
  ol,
  a {
    font-size: 1.125rem;
    line-height: 1.444444444;
    letter-spacing: 0.2px;
    margin-top: 0;
  }
  ul,
  ol {
    padding-left: 1em;
    li {
      list-style-position: outside;
    }
  }
  a {
    color: #9c61e8;
  }
  b,
  h3 {
    font-weight: $font-regular;
  }
  h3 {
    font-size: 1.125rem;
    line-height: 1.666666667;
    margin: 0;
  }
}

.blog-comments {
  margin-top: 3.75rem;
  margin-bottom: 6.25rem;
}

.pagination {
  margin-bottom: 6.25rem;
  overflow: hidden;
}
.pagination__prev {
  float: left;
}

.pagination__next {
  float: right;
}
