.language-switch {
  flex: 0 0 auto;
  position: relative;
  text-transform: uppercase;
  font-family: inherit;
  text-transform: inherit;
  background: none;
  border: 0;
  padding: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  color: currentColor;

  &__icon {
    flex: 0 0 auto;
    fill: currentColor;
    width: 5px;
    height: 5px;
    margin-left: 7px;
    transform: translateY(-2px);
    display: inline-block;
    opacity: 0.5;
    transition: opacity 200ms;

    &:hover {
      opacity: 1;
    }
  }

  &:focus {
    outline: 0;
  }

  &__text {
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 200ms;

    &:hover {
      opacity: 1;
    }
  }
  &.is-open &__text {
    opacity: 1;
  }
  &.is-open &__list {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }

  &__list {
    display: flex;
    flex-flow: column nowrap;
    padding: 0;
    background-color: #fff;
    border: 1px solid rgba(69, 67, 76, 0.1);
    box-shadow: 0px 1px 2px 0px rgba(black, 0.1);
    border-radius: 3px;
    margin: 0;
    position: absolute;
    bottom: calc(100% + 0.625rem);
    left: -$space2;
    list-style: none;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.2s, transform 0.2s;
    z-index: 2;
    pointer-events: none;

    > a {
      text-align: left;
      display: block;
      padding: $space1 $space2;
      transition: color 200ms, opacity 200ms;
      opacity: 0.5;
      &:first-child {
        padding-top: $space2;
      }
      &:last-child {
        padding-bottom: $space2;
      }
      &:hover {
        color: black;
        opacity: 1;
      }
      &.is-active {
        opacity: 1;
        color: #9c61e8;
        pointer-events: none;
        cursor: none;
      }
    }
  }
}
