.partnerContainer {
  background-color: #fff;
  padding-top: 0.5rem;
  margin: 2rem;
  }

  .partnerLogo {
   height: auto;
   width: 18rem;
   object-fit: contain;
   margin-left: auto;
   margin-right: auto;
  }

.logoTextWrapper {
display: flex;
justify-content: center;
flex-direction: column;
&:nth-child(2n + 2) {
  background-color: #fff;
}
@include md-max {
 max-width: none;
}
}

  .linkWrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    @include md-max {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

.heading1 {
  color: #9c61e8;
  font-size: 4rem;
  margin: 2rem 1rem;
  text-align: center;
  @include md-max {
    font-size: 2.5rem;
  }
}

// .heading2 {
//  color: #000;
//  font-size:2rem;
//  text-align: center;
//  margin-bottom: 6rem;
//  @include md-max {
//   font-size: 1.5rem;
// }
// }

.partner-info-paragraph {
  opacity: 0.7;
  font-size: 1.5rem;
  color: #45434c;
  text-align: left;
  @include md-max {
    font-size: 1.1875rem;
  }
}

.partner-info-btn {
  background-color: #333;
  border-color: #333;
  margin-bottom: 0;
  margin-right: 1.5rem;
  @include md-max {
margin-bottom: 1rem;
  }
}

.partner-info-btn2 {
  margin-top: 0;
  @include md-max {
  margin-top: 1rem;
  }
}
