@font-face {
  font-family: 'Larsseit';
  src: url('/resources/fonts/32129D_0_0.woff2') format('woff2'),
    url('/resources/fonts/32129D_0_0.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Larsseit';
  src: url('/resources/fonts/32129D_1_0.woff2') format('woff2'),
    url('/resources/fonts/32129D_1_0.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Larsseit';
  src: url('/resources/fonts/3225EF_0_0.woff2') format('woff2'),
    url('/resources/fonts/3225EF_0_0.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}
