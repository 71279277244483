.pricing {
  display: flex;
  text-align: center;
  width: 100%;
  flex-flow: column nowrap;
  &:last-of-type {
    padding-bottom: 10.5rem;
  }
  @include sm-max {
    padding-bottom: 0;
  }
  &--inline {
    padding-bottom: 10.5rem;
    @include sm-max {
      padding-top: 3.125rem;
      padding-bottom: 3.125rem;
    }
  }

  // <header> tag
  &__header {
    margin-bottom: 3rem;
    padding-top: 3rem;
    @include md-max {
      margin-bottom: 2.5rem;
      padding-top: 1.875rem;
    }
  }
  &--inline &__header {
    margin-bottom: 4.375rem;

    @include md-max {
      margin-bottom: 3.125rem;
    }
  }

  // <h2> tag
  &__heading {
    font-size: 2em;
    margin-bottom: 0.625rem;
    @include sm-max {
      font-size: 1.5rem;
    }
  }
  // &__footer {
  //   @include md-max {
  //     padding-left: 0.9375rem;
  //     padding-right: 0.9375rem;
  //   }
  // }
}
