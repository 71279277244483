.pricing-new {
    color: #2D2F32;
    font-size: 16px;
    margin-top: 50px;

    &__switch {
        margin-top: 50px;
    }

    & h2 {
        font-size: 32px;
        font-weight: 500;

        @include to-medium {
            font-size: 24px;
        }
    }
}

.pricing-container {
    display: inline-flex;
    max-width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    gap: 24px;
    margin-top: 38px;
    padding: 12px;
    overflow-x: auto;
    overflow-y: visible;
    cursor: grab;

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @include to-medium {
        .product-free {
            order: 3;
        }
    }
}

.pricing-card {
    width: 300px;
    height: 505px;
    display: block;
    background: linear-gradient(225deg, #FF79FF 0%, #9C61E8 100%);
    padding: 4px;
    border-radius: 12px;
    text-align: left;
    color: #2D2F32;
    font-size: 16px;
    transition: transform 0.2s, box-shadow 0.2s;
    flex: 0 0 auto;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    }

    &.product-free {
        background: #D9DCDF;
        padding: 1px;
    }

    &__inner {
        width: 100%;
        height: 100%;
        background: white;
        border-radius: 8px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .product-free & {
          border-radius: 11px;
          padding: 23px;
        }
    }

    &__users {
        display: flex;
        flex-flow: row nowrap;
    }

    &__recommended-for {
        background-color: #E9DDF9;
        font-size: 16px;
        padding: 4px;
        margin-top: 10px;
        font-weight: 500;
    }

    &__name {
        margin-top: 30px;
        font-weight: 500;

        .free & {
            color: #6D747B;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 40px;
        margin-top: 10px;
        line-height: 52px;

        .free & {
            color: #6D747B;
        }
    }

    &__subtitle {
        margin-top: 10px;
        font-weight: 500;
        color: #6D747B;
    }

    &__separator {
        flex: 1 1 auto;
    }

    &__pricing {
        font-size: 20px;
        font-weight: 500;
        color: #555;
    }

    &__cta {
        background: linear-gradient(225deg, #FF79FF 0%, #9C61E8 100%);
        border-radius: 8px;
        padding: 12px;
        padding-top: 14px;
        text-align: center;
        width: 100%;
        color: white;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin-top: 10px;

        .product-home & {
            border: 1px solid #AB6BFF;
            background: white;
            color: #AB6BFF;
        }

        .product-free & {
            border: 1px solid #868F98;
            background: white;
            color: #868F98;
        }
    }

    &__footer {
        color: #9C61E8;
        margin-top: 12px;
        height: 20px;
    }
}

.user-svg {
    width: 24px;
    height: 24px;
}

.pricing-switch {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.switch {
    user-select: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 20px;
    font-weight: 500;

    & label {
        cursor: pointer;
        line-height: 32px;
    }

    &__label-left {
        order: 1;
        padding-right: 8px;
    }

    &__label-right {
        order: 3;
        color: #868F98;
        padding-left: 8px;
    }

    & input {
        order: 2;
        cursor: pointer;
        place-content: center;
        -webkit-appearance: none;
        appearance: none;
        outline: 0;
        width: 52px;
        height: 32px;
        border-radius: 32px;
        background-color: #2D2F32;
    }

    & input::before {
        content: "";
        display: block;
        position: relative;
        left: 2px;
        top: 0px;
        width: 28px;
        height: 28px;
        transition: 120ms left ease-in-out;
        background-color: white;
        border-radius: 28px;
    }

    & input:checked::before {
        left: 22px;
    }

    & input:checked + .switch__label-left {
        color: #868F98;
    }

    & input:checked ~ .switch__label-right {
        color: #2D2F32;
    }
}
