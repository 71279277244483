.error {
  text-align: center;
  color: #45434c;
  max-width: 45.375rem;
  width: 100%;
  margin: 9.375rem auto 12.5rem;
  a {
    color: #9c61e8;
  }
  @include sm-max {
    margin: 5rem 0;
  }
}

.error__image {
  margin-bottom: 9.375rem;
  @include sm-max {
    max-width: 50%;
    margin-bottom: 5rem;
  }
}

.error__title {
  color: #000000;
  margin-bottom: 0.625rem;
}

.error__section {
  text-align: left;
  margin-top: 5rem;
  > p {
    margin-bottom: 2.125rem;
  }
  &:first-of-type {
    margin-top: 0;
  }
  @include sm-max {
    margin-top: 3rem;
  }
}
