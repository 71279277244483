.site-footer {
  margin-top: auto;
  color: rgba(#45434c, 0.6);
  border-top: 1px solid #eaeaea;
  width: 100%;
  display: grid;
  grid-template-areas:
    'nav  nav'
    'logo lang';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  row-gap: $space2;
  position: relative;
  padding-top: $space3;
  padding-bottom: $space6;
  padding-left: 7.222222%;
  padding-right: 7.222222%;
  white-space: nowrap;
  text-transform: uppercase;
  color: black;

  @include from-large {
    padding-top: $space5;
    row-gap: 0;
    grid-template-areas: 'logo nav lang';
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
  }
  &__logo {
    grid-area: logo;
    opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > span {
      padding-left: 1em;
    }
  }
  &__org-number {
    grid-area: logo;
    grid-row: 3;
    opacity: 0.6;
    place-self: start;

    @include from-large {
      grid-row: 2;
      place-self: center;
    }
  }
  &__language {
    grid-area: lang;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__nav {
    grid-area: nav;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;

    > a {
      opacity: 0.5;
      padding: $space1;
      @include from-large {
        padding: $space1 $space2;
      }
      transition: opacity 200ms;

      &:hover {
        opacity: 1;
      }
    }
  }
}
